var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.meshInfo
    ? _c("div", { staticClass: "label" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.meshInfo.name))
        ]),
        _vm.meshInfo.labels && _vm.meshInfo.labels.length > 0
          ? _c(
              "div",
              { staticClass: "content" },
              _vm._l(_vm.meshInfo.labels, function(item, idx) {
                return _c("div", { key: idx, staticClass: "item" }, [
                  _vm._v(
                    " " +
                      _vm._s(item.name) +
                      "： " +
                      _vm._s(
                        (_vm.meshData[_vm.meshName] &&
                          _vm.meshData[_vm.meshName][item.key]) ||
                          "---"
                      ) +
                      " "
                  )
                ])
              }),
              0
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }