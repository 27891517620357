import "core-js/modules/es.number.constructor"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    availableMeshs: {
      type: Array,
      "default": function _default() {return [];},
      required: true },

    // 存储变量名称
    meshName: {
      type: String,
      required: true },

    idx: {
      type: [Number, String],
      required: true } },


  data: function data() {
    return {
      meshData: {} };

  },
  watch: {
    '$store.state.meshData.num': function $storeStateMeshDataNum(v) {
      this.meshData = this.$store.state.meshData;
    } },

  computed: {
    info: function info() {
      return this.meshData[this.meshName] || null;
    },
    meshInfo: function meshInfo() {
      return this.availableMeshs[this.idx];
    } },

  methods: {} };